import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  alertText: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  createDateSpan: {
    fontWeight: 'normal',
    paddingLeft: 125,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  isAcknowledgedSpan: {
    fontWeight: 'normal',
    paddingLeft: 71,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  titleSpan: {
    fontWeight: 'normal',
    paddingLeft: 140,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  linkTitleSpan: {
    fontWeight: 'normal',
    paddingLeft: 109,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  descriptionStyle: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: '100%',
    },
  },
  dialogTitleHighAlert: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    '&:hover': {
      background: theme.palette.base.red,
      color: theme.palette.base.white,
    },
  },
  dialogTitleEmployeeAlertAlert: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    '&:hover': {
      background: theme.palette.base.red,
      color: theme.palette.base.white,
    },
  },
  dialogTitleStudentAlertAlert: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    '&:hover': {
      background: theme.palette.base.red,
      color: theme.palette.base.white,
    },
  },
  dialogTitleMediumAlert: {
    background: '#ff9800',
    color: theme.palette.base.white,
    '&:hover': {
      background: '#ff9800',
      color: theme.palette.base.white,
    },
  },
  dialogTitleLowAlert: {
    background: '#ff9800',
    color: theme.palette.base.white,
    '&:hover': {
      background: '#ff9800',
      color: theme.palette.base.white,
    },
  },
  dialogTitleITAlertAlert: {
    background: theme.palette.base.blue,
    color: theme.palette.base.white,
    '&:hover': {
      background: theme.palette.base.blue,
      color: theme.palette.base.white,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default styles
