/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import Box from '@material-ui/core/Box'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {
  Typography,
  Grid,
  Card,
  Divider,
  Avatar,
  TextField,
  Button,
} from '@material-ui/core'
import PageLink from '../components/PageLink'
import PageToggle from '../components/PageToggle'

import {
  fetchUser,
  selectSettingsData,
  toggleClosestStop,
  toggleTheme,
  selectUserData,
  toggleAdminMode,
  sendMyAppsRefresh,
} from '../settingsSlice'
import {
  fetchMyProfile,
  fetchProfileLogEvent,
  fetchProxyFSProfile,
  fetchProxyProfile,
  handleProxyLogout,
} from '../../dashboard/widgets/MyProfile/myProfileSlice'
import settingsStyles from '../settingsStyles'
import PasswordModal from '../../admin/components/AdminPasswordModal/AdminPasswordModal'
import { handleLogout } from '../../dashboard/dashboardSlice'
import { getImageFromFirebaseWithHook } from '../../apps/helpers'

const parser = new UAParser()

export const handleLogoutClick = (dispatch, userProfile) => {
  dispatch(
    fetchProfileLogEvent({
      module: 'Core',
      object: 'Auth',
      objectId: 'Logout',
      action: 'Click',
      actionContext:
        localStorage.getItem('user') === '"visitor"' ? 'Guest' : 'NetID',
      actionContextValue: '',
      rcpid:
        localStorage.getItem('rutgersEduRCPID') === '"visitor"'
          ? 'Guest'
          : localStorage.getItem('rutgersEduRCPID'),
      info: JSON.stringify(parser.getResult()),
      timestamp: Date.now(),
    }),
  ).then(() => {
    if (userProfile.proxyNetId) {
      dispatch(handleProxyLogout())
    } else {
      dispatch(handleLogout())
    }
  })
}

// TODO Navigating to settings should show you as on the dashboard,
// myApps, News, Maps tab and highlight that as the active tab
// This should be done after SideNav rework.
function Main() {
  const classes = settingsStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const settingsData = useSelector(selectSettingsData)
  const userData = useSelector(selectUserData)
  const { userProfile } = useSelector((s) => s.myProfile)
  const { adminMode, data } = useSelector((s) => s.settings)
  const { path } = useRouteMatch()
  const [netID, updateNetID] = React.useState('')
  const [openAdminPasswordModal, setOpenAdminPasswordModal] =
    React.useState(false)
  const [toggleAppRefresh, updateToggleAppRefresh] = React.useState(false)
  const [image, setImg] = React.useState(
    './assets/images/profile_default_avatar.png',
  )

  React.useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchMyProfile())
  }, [])

  function handleLinkPress(link) {
    history.push(`${path}${link}`)
  }

  function handleAvatarPress() {
    const isLoggedInUser = userProfile && userProfile.firstName
    if (isLoggedInUser) {
      handleLinkPress('/profile')
    } else {
      handleLogoutClick(dispatch, userProfile)
    }
  }

  const handleThemeModeToggle = () => {
    dispatch(toggleTheme())
  }

  const handleBusStopToggle = () => {
    dispatch(toggleClosestStop())
  }

  const handleAdminMode = () => {
    if (!adminMode) {
      setOpenAdminPasswordModal(true)
    } else {
      dispatch(toggleAdminMode(false))
    }
  }

  const handleProxyTextField = (e) => {
    updateNetID(e.target.value)
  }

  const handleProxyProfileClick = () => {
    dispatch(fetchProxyProfile(netID)).then(() => {
      dispatch(fetchProxyFSProfile()).then(() => {
        history.push('/')
      })
    })
  }

  const handleAppRefreshToggle = () => {
    dispatch(sendMyAppsRefresh()).then(() => {
      updateToggleAppRefresh(!toggleAppRefresh)
    })
  }

  const handleLogoutClickHandler = () => {
    handleLogoutClick(dispatch, userProfile)
  }

  const handleEnrollmentClick = () => {
    window.open(process.env.REACT_APP_ENROLLMENT_URL, '_blank')
  }

  const handleNotificationsClick = () => {
    window.open(process.env.REACT_APP_NOTIFICATIONS_URL, '_blank')
  }

  const proxyValue = userProfile?.proxyNetId
  let proxyMode
  if (userProfile?.proxyNetId === null || userProfile?.proxyMode === '') {
    proxyMode = false
  } else {
    proxyMode = true
  }
  // dont pass rbhsStudent param if it isnt true
  const rbhsStudent = userProfile?.isRbhsStudent === true ? true : null

  const url = `${
    process.env.REACT_APP_MYGUEST_HOST
  }?parentToken=${localStorage.getItem('myrJwtToken')}&netid=${
    userData ? userData.netid : 'error'
  }${proxyValue ? `&proxyNetId=${proxyValue}` : ''}${
    proxyMode ? `&proxyMode=${proxyMode}` : ''
  }${rbhsStudent ? `&rbhsStudent=${rbhsStudent}` : ''}`

  React.useEffect(() => {
    if (data?.user?.idPhotoLink) {
      getImageFromFirebaseWithHook(data?.user?.idPhotoLink, setImg)
    }
  }, [])

  return (
    <Box className={classes.centerPage}>
      <PasswordModal
        open={openAdminPasswordModal}
        setOpen={setOpenAdminPasswordModal}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.sectionTitle}
        >
          <Grid item>
            <Typography variant="h1">
              <b>App Settings</b>
            </Typography>
          </Grid>
          <Grid item>
            {data?.user?.idPhotoLink ? (
              <Avatar onClick={handleAvatarPress} className={classes.avatar}>
                <img
                  className={classes.idProfilePictureImageForSettings}
                  src={image}
                  alt="profile"
                  onError={(e) => {
                    e.target.onerror = null
                    e.target.src = './assets/images/profile_default_avatar.png'
                  }}
                />
              </Avatar>
            ) : (
              <Avatar onClick={handleAvatarPress} className={classes.avatar}>
                {userProfile && userProfile.firstName
                  ? userProfile.firstName?.substring(0, 1)
                  : 'V'}
              </Avatar>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="Logout"
              description="Log user out"
              link={handleLogoutClickHandler}
              icon={<ExitToAppIcon />}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            {userProfile.hasAdminPermission === 'true' ? (
              <>
                <PageToggle
                  title="Admin Mode"
                  description="Enter Admin Mode"
                  action={handleAdminMode}
                  state={adminMode}
                />
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            <PageLink
              title="App Info"
              description="Build information for the app"
              path="/appinfo"
              link={handleLinkPress}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageLink
              title="Dashboard Settings"
              link={handleLinkPress}
              path="/dashboard"
              description="Update tutorials and more"
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageToggle
              title="Dark Mode"
              description="Change appearance of dashboard"
              action={handleThemeModeToggle}
              state={data.darkMode}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageToggle
              title="Maps Auto Select"
              description="Automatically use the nearest bus stop"
              action={handleBusStopToggle}
              state={settingsData.closestStop}
            />
          </Card>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.sectionTitle}
        >
          <Typography variant="h1">
            <b>Security</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            {userProfile?.isRuStudent ||
            userProfile?.isRbhsStudent ||
            userProfile?.isAdmit ||
            userProfile?.isAdmitComing ? (
              <>
                <PageLink
                  title="Authorized Access"
                  description="Set up other users to view your account"
                  link={() => window.open(url, '_blank')}
                />
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            {userProfile.hasProxyPermission === 'true' &&
            !userProfile.proxyNetId ? (
              <>
                <PageLink
                  title="Proxy Mode"
                  description="Enter the netid of the user you want to proxy as."
                />
                <Box display="flex" marginLeft="10px" marginBottom="10px">
                  <TextField
                    id="proxy-mode"
                    label="NetID"
                    variant="outlined"
                    size="small"
                    value={netID}
                    onChange={handleProxyTextField}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      marginLeft: 10,
                    }}
                    size="small"
                    onClick={handleProxyProfileClick}
                  >
                    Submit
                  </Button>
                </Box>
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            {userProfile.hasNotifAdminPermission === 'true' ? (
              <>
                <PageLink
                  title="Notifications Tool"
                  description="Notifications Dashboard Tool"
                  action={handleNotificationsClick}
                />
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            {userProfile.hasEnrollAdminPermission === 'true' ? (
              <>
                <PageLink
                  title="Enrollment Tool"
                  description="Enrollment Reporting Tool"
                  action={handleEnrollmentClick}
                />
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            {userProfile.hasMyAppRefreshPermission === 'true' ? (
              <>
                <PageToggle
                  title="Refresh App Permission"
                  description="Use to refresh the apps permission"
                  action={handleAppRefreshToggle}
                  state={toggleAppRefresh}
                />
                <Divider variant="middle" className={classes.fullWidth} />
              </>
            ) : null}
            <PageLink
              title="Device Managment"
              description="Configure what devices can access the app"
              link={handleLinkPress}
              path="/deviceManagement"
            />
          </Card>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.sectionTitle}
        >
          <Typography variant="h1">
            <b>Support</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="App Support"
              description="Find answers to commonly asked questions"
              link={handleLinkPress}
              path="/appSupport"
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageLink
              title="Feedback"
              description="Let Rutgers know your thoughts on the myRutgers app"
              path="/feedback"
              link={handleLinkPress}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageLink
              title="Credits"
              description="See the makers of the app"
              path="/credits"
              link={handleLinkPress}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Main
