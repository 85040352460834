import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  content: {
    padding: 0,
    height: `515px !important`,
  },
  profilePictureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    objectFit: 'cover',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconPicture: {
    margin: '20px 0',
    borderRadius: '100%',
    background: '#f7f7f7',
    width: '80px',
    height: '80px',
    textAlign: 'center',
    padding: '14px 0',
  },
  title: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    padding: 2,
    fontWeight: 'bold',
    color: theme.palette.primary.headerText,
  },
  subtitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.secondary.headerText,
  },
  tableRow: {
    display: 'flex',
    width: '100%',
  },
  tableCellLabel: {
    color: theme.palette.secondary.headerText,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'right',
    width: '50%',
    paddingRight: 5,
  },
  tableCellValue: {
    flexGrow: 1,
    fontSize: 14,
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  eyeIconStyle: {
    color: theme.palette.secondary.headerText,
  },
  mobileValueRow: {
    display: 'flex',
    justifyContent: 'left',
  },
  icon: {
    fontSize: 30,
    color: theme.palette.secondary.headerText,
    width: '100%',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  profileBox: {
    height: 480,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      height: 465,
    },
  },
  profileNameChangeBox: {
    height: '100%',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      height: 465,
    },
  },
  root: {
    height: 35,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  rootTabBox: {
    height: 35,
    [theme.breakpoints.down(370)]: {
      width: 300,
    },
  },
  rootTabContainer: {
    borderTop: 'solid',
    borderColor: theme.palette.backgroundColor.ternary,
    borderWidth: '1px',
  },
  tabIndicator: {
    '&.MuiTabs-indicator': {
      backgroundColor: `${theme.palette.base.blue} !important`,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popovercontent: {
    padding: '3px 4px',
  },
  profileDragDropContainer: {
    borderColor: '#eeeeee',
    borderWidth: 2,
    borderStyle: 'dashed',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 20,
  },
  backButton: {
    backgroundColor: theme.palette.base.red,
  },
  buttonText: {
    color: theme.palette.base.white,
    fontSize: 15,
  },
  arrowImage: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
  },
  headerRedBkg: {
    backgroundColor: theme.palette.base.red,
    padding: '10px 0px',
    marginTop: 10,
    whiteSpace: 'pre-line',
  },
  headerGrayBkg: {
    backgroundColor: theme.palette.base.gray,
    padding: '10px 0px',
    marginTop: 10,
    whiteSpace: 'pre-line',
  },
  headerText: {
    color: theme.palette.base.white,
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  addtionalContentTitle: {
    color: 'black',
    paddingTop: 10,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  linkText: {
    color: 'black',
    textAlign: 'left',
  },
  tabLinkBox: {
    width: 80,
    height: 70,
    textAlign: 'center',
    margin: '0 auto',
  },
  tabLinkText: {
    fontWeight: 400,
    margin: 0,
    maxHeight: 26,
    maxWidth: 80,
    fontSize: '10px',
    overflow: 'hidden',
    display: '-webkit-box',
    lineHeight: 1.3,
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  nameChangeGrid: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.hover,
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.button.hover,
    },
  },
  linkButton: {
    textAlign: 'left',
  },
}))

export default styles
