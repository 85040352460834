/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Tabs, Tab } from '@material-ui/core'

export const RutgersTabs = withStyles((theme) => ({
  root: {
    height: 35,
    minHeight: 35,
  },
  indicator: {
    width: '100%',
    backgroundColor: theme.palette.base.red,
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

export const RutgersTab = withStyles((theme) => ({
  root: {
    overflowY: 'hidden',
    display: 'flex',
    flex: '1',
    minHeight: 35,
    height: 35,
    minWidth: 30,
    width: 30,
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
}))((props) => <Tab disableRipple {...props} />)
