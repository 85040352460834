import { makeStyles, createStyles } from '@material-ui/styles'

export const useVehicleStopArrivalListDropDown = makeStyles((theme) =>
  createStyles({
    appBarIcon: {
      fontSize: 24,
    },
    root: {
      borderRadius: '8px',
      padding: '0px 10px',
    },
    alertMenu: {
      marginTop: 3,
      marginLeft: 5,
    },
    flex: {
      display: 'flex',
    },
    gridButton: {
      width: '24px',
      padding: '0px 14px',
      marginRight: 8,
    },
    checkCircleOutline: {
      color: theme.palette.base.green,
      marginLeft: 20,
    },
    text: {
      fontSize: 18,
      marginLeft: 8,
    },
    listItemBody: {
      color: theme.palette.primary.headerText,
    },
    listItemText: {
      color: theme.palette.primary.headerText,
      cursor: 'pointer',
    },
    routeLongNameText: {
      fontSize: 18,
      color: 'black',
      fontWeight: 'bold',
      backgroundColor: theme.palette.components.bottomSheetOverlay,
      padding: '0px 8px',
      borderRadius: '4px',
    },
  }),
)

export default useVehicleStopArrivalListDropDown
