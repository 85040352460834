/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React from 'react'
import { collection, getDoc, doc, updateDoc } from 'firebase/firestore'
import { useSelector, useDispatch } from 'react-redux'
// import { doc, collection, updateDoc } from 'firebase/firestore'
// import { useDropzone } from 'react-dropzone'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import {
  Card,
  Popover,
  Typography as Text,
  Typography,
  Link,
  Tooltip,
} from '@material-ui/core'
// import imageCompression from 'browser-image-compression'

import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Widget from '../Widget'
import Loading from '../helpers/Loading'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import MyProfileStyles from './MyProfileStyles'
// import { firestore, storage } from '../../../../../utils/firebase'
import {
  fetchMyProfile,
  updateTabValue,
  selectMyProfileData,
  selectMyProfileStatus,
  //   updateDialog,
} from './myProfileSlice'
import { selectShowSensitiveInfo } from '../../../settings/settingsSlice'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
// import DashboardDialog from '../../DashboardComponents/DashboardDialog/DashboardDialog'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { logDashboardEvent } from '../../events'
import { firestore } from '../../../../../utils/firebase'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MyProfile({ className, widgetId }) {
  const dispatch = useDispatch()
  const classes = MyProfileStyles()
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const widgetData = useSelector(selectMyProfileData)
  const widgetStatus = useSelector(selectMyProfileStatus)

  React.useEffect(() => {
    dispatch(fetchMyProfile())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Content className={classes.content}>
        {renderProfileState(
          widgetStatus,
          widgetData,
          className,
          showSensitiveInfo,
          widgetId,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderProfileState(
  widgetStatus,
  widgetData,
  className,
  showSensitiveInfo,
  widgetId,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          className={className}
          showSensitiveInfo={showSensitiveInfo}
          widgetId={widgetId}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>not loaded default state</Grid>
        </Grid>
      )
  }
}

function ProfileWidgetTabs(props) {
  const { tabs } = props
  const classes = MyProfileStyles()
  const dispatch = useDispatch()
  const { tabValue } = useSelector((s) => s.myProfile)

  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }

  return (
    <Box className={classes.rootTabBox}>
      <RutgersTabs
        value={tabValue}
        onChange={handleChange}
        className={classes.rootTabContainer}
        aria-label="profile tabs"
        variant="fullWidth"
      >
        {/* Tabs should be props that each widget should create manually, to style and make calls */}
        {tabs.map((tab, index) => (
          <RutgersTab
            key={index}
            tabIndex={index}
            className={classes.tabIndicator}
            label={tab}
            {...a11yProps(index)}
          />
        ))}
      </RutgersTabs>
    </Box>
  )
}

function Loaded({ widgetData, showSensitiveInfo, widgetId }) {
  const classes = MyProfileStyles()
  const dispatch = useDispatch()
  //   dialogOpen
  const { tabValue } = useSelector((s) => s.myProfile)

  const [openProfileNameChangeView, setOpenProfileNameChangeView] =
    React.useState(true)

  const [anchorElement, setAnchorElement] = React.useState(null)
  const [clickedCopy, setClickedCopy] = React.useState(false)
  const [image, setImg] = React.useState(
    './assets/images/profile_default_avatar.png',
  )

  const [showData, setShowData] = React.useState(showSensitiveInfo)
  const toggleShow = () => {
    setShowData(!showData)
  }
  React.useEffect(() => {
    setShowData(showSensitiveInfo)
  }, [showSensitiveInfo])

  React.useEffect(() => {
    if (widgetData && widgetData.avatar) {
      getImageFromFirebaseWithHook(widgetData.avatar, setImg)
    } else if (widgetData && widgetData.idPhotoLink) {
      getImageFromFirebaseWithHook(widgetData.idPhotoLink, setImg)
    }
  }, [])

  const open = Boolean(anchorElement)
  const roles = widgetData.roleTabs.map((tab) => tab.roleTabLabel)
  const email =
    widgetData?.email?.length < 20
      ? widgetData?.email
      : widgetData?.email?.substring(0, 19).concat('...')

  const handlePopoverClicked = () => {
    setClickedCopy(true)
    navigator.clipboard.writeText(widgetData.email)
  }

  const handlePopoverOpen = (event) => {
    setClickedCopy(false)
    setAnchorElement(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElement(null)
  }

  //   const handleUpdateDialog = () => {
  //     const isGuest = localStorage.getItem('user') ?? 'visitor'
  //     if (isGuest !== 'guest' || isGuest !== '') {
  //       dispatch(updateDialog())
  //     }
  //   }

  const switchToProfileNameChange = () => {
    setOpenProfileNameChangeView(!openProfileNameChangeView)
  }
  const handlePress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      setOpenProfileNameChangeView(!openProfileNameChangeView)
    }
  }

  const openLink = (buttonTitle, link) => {
    window.open(link, '_blank')
    handleEvent(buttonTitle, link)
  }

  const handleEvent = (buttonTitle, link) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }

  if (openProfileNameChangeView) {
    return (
      <>
        <Box className={classes.profileBox}>
          <Box
            // component="a"
            // onClick={handleUpdateDialog}
            className={classes.profilePictureContainer}
          >
            {widgetData.avatar === null && widgetData.idPhotoLink === null ? (
              <Box className={classes.iconPicture}>
                <IconPicker
                  iconName="far fa-user"
                  size="4x"
                  color="gray"
                ></IconPicker>
              </Box>
            ) : (
              <MyProfileAvatar classes={classes} image={image} />
            )}

            <Text className={classes.title}>
              {widgetData.firstName} {widgetData.lastName}
            </Text>
          </Box>
          <Divider />
          <Box paddingY={0.5}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <div className={classes.tableRow}>
                <Text className={classes.tableCellLabel}>NetID:</Text>

                <Text className={classes.tableCellValue}>
                  {widgetData.netID}
                </Text>
              </div>

              {widgetData.ruID && (
                <div className={classes.tableRow}>
                  <Text className={classes.tableCellLabel}>RUID:</Text>

                  <Text className={classes.tableCellValue}>
                    {widgetData.ruID}
                  </Text>
                </div>
              )}
              {widgetData.anumber && (
                <div className={classes.tableRow}>
                  <Text className={classes.tableCellLabel}>RBHSID:</Text>

                  <Text className={classes.tableCellValue}>
                    {widgetData.anumber}
                  </Text>
                </div>
              )}

              {widgetData.employeeID && (
                <div className={classes.tableRow}>
                  <Text className={classes.tableCellLabel}>Employee ID:</Text>

                  <Text className={classes.tableCellValue}>
                    {widgetData.employeeID}
                  </Text>
                </div>
              )}

              {widgetData.mobile && widgetData.mobile.length !== 0 && (
                <div className={classes.tableRow}>
                  <Text className={classes.tableCellLabel}>Mobile Phone:</Text>
                  <div className={classes.mobileValueRow}>
                    <Text className={classes.tableCellValue}>
                      {showData ? widgetData.mobile : '********** '}
                    </Text>
                    {showData ? (
                      <Visibility
                        onClick={toggleShow}
                        className={classes.eyeIconStyle}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={toggleShow}
                        className={classes.eyeIconStyle}
                      />
                    )}
                  </div>
                </div>
              )}
              {email ? (
                <div className={classes.tableRow}>
                  <Text className={classes.tableCellLabel}>Email:</Text>
                  <Text
                    className={classes.tableCellValue}
                    onClick={handlePopoverClicked}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {email}
                  </Text>
                  <Popover
                    open={open}
                    anchorEl={anchorElement}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    className={classes.popover}
                    disableRestoreFocus
                  >
                    <Typography className={classes.popovercontent}>
                      {clickedCopy ? 'Copied Email!' : 'Click to Copy'}
                    </Typography>
                  </Popover>
                </div>
              ) : null}
            </Box>
          </Box>

          <Divider />

          <Box paddingY={0.5}>
            <Text className={classes.subtitle}>{widgetData.school}</Text>
            <Text className={classes.subtitle}>{widgetData.campus}</Text>
            <Box style={{ marginTop: 5, marginBottom: 6 }}>
              {widgetData.roleTabs.map((tab, index) => (
                <TabPanel key={index} value={tabValue} index={index}>
                  <>
                    <Typography align="center" variant="subtitle1">
                      {tab.title}
                    </Typography>
                    <Typography align="center" variant="subtitle2">
                      {tab.subtitle}
                    </Typography>
                  </>
                  <Widget.Spacer />
                  <Grid container direction="row" justifyContent="center">
                    {tab.iconLinks.map((icon) => (
                      <Grid
                        container
                        key={icon.iconLabel + icon.link}
                        className={classes.verticalSpace}
                        direction="column"
                        alignContent="center"
                        item
                        xs={3}
                      >
                        {icon.iconLabel === 'Name Change' ? (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection="column"
                            className={classes.tabLinkBox}
                          >
                            <Grid
                              container
                              tabIndex={0}
                              onClick={switchToProfileNameChange}
                              onKeyPress={handlePress}
                              className={classes.nameChangeGrid}
                            >
                              <Grid item xs={12}>
                                <IconPicker
                                  iconName={icon.icon}
                                  color="gray"
                                  size="2x"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  flexDirection="column"
                                  className={classes.textCenter}
                                >
                                  <Typography
                                    variant="body2"
                                    id="tab-link-label"
                                    component="p"
                                    color="textPrimary"
                                    className={classes.tabLinkText}
                                  >
                                    {icon.iconLabel}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : (
                          <Widget.TabLink
                            icon={
                              <IconPicker
                                iconName={icon.icon}
                                size="2x"
                                color="gray"
                              />
                            }
                            iconLabel={icon.iconLabel}
                            hyperLink={icon.link}
                            widgetId={widgetId}
                            dispatch={dispatch}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              ))}
            </Box>
          </Box>
        </Box>
        <ProfileWidgetTabs tabs={roles} />
        {/* <DashboardDialog
          dialogTitle={<Text variant="h3">Change your profile picture</Text>}
          open={dialogOpen}
          dispatch={dispatch}
          onCloseDialog={fetchMyProfile}
          closeDialog={updateDialog}
          dialogContent={
            <ProfileDragDrop
              classes={classes}
              dispatch={dispatch}
              avatar={image}
            />
          }
        /> */}
      </>
    )
  }
  return (
    <>
      <Box className={classes.profileNameChangeBox}>
        <Button
          className={classes.backButton}
          onClick={switchToProfileNameChange}
          onKeyPress={handlePress}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item className={classes.arrowImage}>
              <IconPicker
                iconName="fa-arrow-left"
                size="2x"
                color="white"
              ></IconPicker>
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.buttonText}>
                Back to Profile
              </Typography>
            </Grid>
          </Grid>
        </Button>
        <Grid
          container
          direction="column"
          className={classes.fullHeight}
          justifyContent="flex-start"
        >
          <Grid item>
            <Card variant="outlined" className={classes.headerRedBkg}>
              <Button
                className={classes.linkButton}
                onClick={() =>
                  openLink(
                    'Student - Choose/Change your chosen name',
                    'https://scarlethub.rutgers.edu/registrar/personal-information-updates/name-change/#ChosenName',
                  )
                }
              >
                <Typography variant="h5" className={classes.headerText}>
                  Student - Choose/Change your chosen name
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item>
            <Card variant="outlined" className={classes.headerGrayBkg}>
              <Button
                className={classes.linkButton}
                onClick={() =>
                  openLink(
                    'Student - Change your legal name',
                    'https://scarlethub.rutgers.edu/registrar/personal-information-updates/name-change/#LegalChange',
                  )
                }
              >
                <Typography variant="h5" className={classes.headerText}>
                  Student - Change your legal name
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item>
            <Card variant="outlined" className={classes.headerRedBkg}>
              <Button
                className={classes.linkButton}
                onClick={() =>
                  openLink(
                    'Faculty/Staff - Choose/Change your chosen name',
                    'https://personalinfo.rutgers.edu/pi/updateChosenName.htm',
                  )
                }
              >
                <Typography variant="h5" className={classes.headerText}>
                  Faculty/Staff - Choose/Change your chosen name
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item>
            <Card variant="outlined" className={classes.headerGrayBkg}>
              <Button
                className={classes.linkButton}
                onClick={() =>
                  openLink(
                    'Faculty/Staff - Change your legal name',
                    'https://uhr.rutgers.edu/worklife-balance/legal-name-change',
                  )
                }
              >
                <Typography variant="h5" className={classes.headerText}>
                  Faculty/Staff - Change your legal name
                </Typography>
              </Button>
            </Card>
          </Grid>
          <Grid item>
            <Typography variant="h5" className={classes.addtionalContentTitle}>
              Chosen Name additional resources:
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                <Typography variant="h6" className={classes.linkText}>
                  Student Chosen Name&nbsp;
                  <Link
                    href="https://scarlethub.rutgers.edu/registrar/personal-information-updates/name-change/#ChosenNameFAQ"
                    target="_blank"
                    onClick={() =>
                      handleEvent(
                        'Student Chosen Name (FAQ&apos;s)',
                        'https://scarlethub.rutgers.edu/registrar/personal-information-updates/name-change/#ChosenNameFAQ',
                      )
                    }
                  >
                    (FAQ&apos;s)
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" className={classes.linkText}>
                  Employee Chosen Name&nbsp;
                  <Link
                    href="https://uhr.rutgers.edu/worklife-balance/submitting-chosen-name"
                    target="_blank"
                    onClick={() =>
                      handleEvent(
                        'Employee Chosen Name (FAQ&apos;s)',
                        'https://uhr.rutgers.edu/worklife-balance/submitting-chosen-name',
                      )
                    }
                  >
                    (FAQ&apos;s)
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" className={classes.linkText}>
                  Email&nbsp;
                  <Link
                    href="https://tools.rutgers.edu/manage/emails"
                    target="_blank"
                    onClick={() =>
                      handleEvent(
                        'Email (set personalized email alias)',
                        'https://tools.rutgers.edu/manage/emails',
                      )
                    }
                  >
                    (set personalized email alias)
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" className={classes.linkText}>
                  Zoom&nbsp;
                  <Link
                    href="https://rutgers.webex.com/"
                    target="_blank"
                    onClick={() =>
                      handleEvent(
                        'Zoom (edit profile name display)',
                        'https://rutgers.zoom.com/',
                      )
                    }
                  >
                    (edit profile name display)
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="h6" className={classes.linkText}>
                  Webex&nbsp;
                  <Link
                    href="https://rutgers.webex.com/"
                    target="_blank"
                    onClick={() =>
                      handleEvent(
                        'Webex (edit profile name display)',
                        'https://rutgers.webex.com/',
                      )
                    }
                  >
                    (edit profile name display)
                  </Link>
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

function MyProfileAvatar({ classes, image }) {
  const userDocRef = doc(
    collection(firestore, 'users'),
    localStorage.getItem('user'),
    'settings',
    'userState',
  )
  const [showProfileAvatar, updateShowAvatar] = React.useState(true)

  const handleShowOrHideProfileImage = () => {
    const showProfileAvatarBool = !showProfileAvatar
    updateShowAvatar(showProfileAvatarBool)
    updateDoc(userDocRef, { showProfileAvatar: showProfileAvatarBool })
  }

  React.useEffect(() => {
    const fetchShowAvatarBool = async () => {
      const avatarData = await getDoc(userDocRef)
      if (avatarData?.data()?.showProfileAvatar === undefined) {
        updateShowAvatar(true)
      } else {
        updateShowAvatar(avatarData?.data()?.showProfileAvatar)
      }
    }
    fetchShowAvatarBool()

    return () => updateShowAvatar(true)
  }, [])

  return (
    <Box onClick={handleShowOrHideProfileImage}>
      <Tooltip
        title={showProfileAvatar === false ? 'Show Photo' : 'Hide Photo'}
      >
        <img
          className={classes.profilePicture}
          src={
            showProfileAvatar === false
              ? './assets/images/profile_default_avatar.png'
              : image
          }
          alt={showProfileAvatar === false ? 'hidden profile' : 'profile'}
        />
      </Tooltip>
    </Box>
  )
}

// function ProfileDragDrop({ classes, avatar }) {
//   const [image, updateImage] = React.useState('')
//   const userId = localStorage.getItem('rutgersEduRCPID')
//   const hasIDPhoto = avatar.includes('_IDPhoto')
//   const handleHideAvatar = () => {
//     const userDocRef = doc(
//       collection(firestore, 'users'),
//       localStorage.getItem('user') ?? 'visitor',
//       'userInfo',
//       'Info',
//     )
//     updateDoc(userDocRef, { avatar: '' })
//   }

//   const handleRestoreIDPhoto = () => {
//     const userDocRef = doc(
//       collection(firestore, 'users'),
//       localStorage.getItem('user') ?? 'visitor',
//       'userInfo',
//       'Info',
//     )
//     updateDoc(userDocRef, { avatar: `/profilePictures/${userId}_IDPhoto.png` })
//   }
//   const onDrop = React.useCallback((acceptedFiles) => {
//     acceptedFiles.forEach(async (file) => {
//       // convert file to jpg
//       const reader = new FileReader()
//       const compressedFile = await imageCompression(file, {
//         maxSizeMB: 1,
//         maxWidthOrHeight: 400,
//       })
//       // console.log('compressed file ', compressedFile)
//       reader.onabort = () => console.log('file reading was aborted')
//       reader.onerror = () => console.log('file reading has failed')
//       reader.onload = () => {
//         const binaryStr = reader.result
//         const blob = new Blob([binaryStr])
//         const srcBlob = URL.createObjectURL(blob)
//         updateImage(srcBlob)
//         let uploadTask
//         if (userId) {
//           uploadTask = storage
//             .ref(`/profilePictures/${userId}_CustomAvatar.png`)
//             .put(compressedFile)
//           const userDocRef = doc(
//             collection(firestore, 'users'),
//             localStorage.getItem('user') ?? 'visitor',
//             'userInfo',
//             'Info',
//           )
//           // update user document collection - avatar example profilePictures/891430_CustomAvatar.jpg
//           updateDoc(userDocRef, {
//             avatar: `/profilePictures/${userId}_CustomAvatar.png`,
//           })
//         }

//         // Listen for state changes, errors, and completion of the upload.
//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//             const progress =
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//             console.log(`Upload is ${progress} % done`)
//             switch (snapshot.state) {
//               case 'paused':
//                 console.log('Upload is paused')
//                 break
//               case 'running':
//                 console.log('Upload is running')
//                 break
//               default:
//                 console.log('upload failed')
//                 break
//             }
//           },
//           (error) => {
//             console.log('error ', error)
//           },
//           () => {
//             // Upload completed successfully, now we can get the download URL
//             console.log('upload completed successfully')
//           },
//         )
//       }
//       reader.readAsArrayBuffer(file)
//     })
//   }, [])
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

//   return (
//     <>
//       <Box paddingBottom="10px">
//         <Text variant="subtitle1">
//           Upload a custom avatar from your device. Image should be square, at
//           least 184px x 184px and .png or .jpg format.
//         </Text>
//       </Box>
//       <Box display="flex" flexDirection="column" alignItems="flex-start">
//         {image && (
//           <Box display="flex" flexDirecton="column" alignItems="center">
//             <Text variant="subtitle1">Current Profile Picture: </Text>
//             <img
//               src={image}
//               className={classes.profilePicture}
//               alt="profile avatar"
//             />
//           </Box>
//         )}
//         <Box className={classes.profileDragDropContainer} {...getRootProps()}>
//           <input {...getInputProps()} />
//           {isDragActive ? (
//             <Text variant="subtitle1">Drop the files here ...</Text>
//           ) : (
//             <Text variant="subtitle1">Upload Custom Photo.</Text>
//           )}
//         </Box>
//         {!hasIDPhoto ? (
//           <Button onClick={handleRestoreIDPhoto}>Restore ID Photo</Button>
//         ) : null}
//         <Button onClick={handleHideAvatar}>Hide Photo</Button>
//       </Box>
//     </>
//   )
// }

export default MyProfile
