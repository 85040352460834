/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Linkify from 'react-linkify'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
// import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// import { storage } from '../../../../../utils/firebase'
import Loading from '../helpers/Loading'
import Widget from '../Widget'

import styles from './JobsInternshipsStyles'
import {
  fetchJobsInternships,
  selectJobsInternshipData,
  selectJobsInternshipStatus,
} from './JobsInternshipsSlice'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function JobsInternships({ className, title, widgetId }) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectJobsInternshipData)
  const widgetStatus = useSelector(selectJobsInternshipStatus)

  React.useEffect(() => {
    dispatch(fetchJobsInternships())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.container}>
        {renderWidgetState(
          widgetStatus,
          widgetData,
          classes,
          widgetId,
          dispatch,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderWidgetState(
  widgetStatus,
  widgetData,
  classes,
  widgetId,
  dispatch,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
          classes={classes}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, widgetId, dispatch, classes }) {
  const [image, setImg] = React.useState(null)

  React.useEffect(() => {
    if (widgetData && widgetData.imageBanner) {
      getImageFromFirebaseWithHook(
        widgetData.imageBanner?.imageLocation,
        setImg,
      )
    }
  }, [])

  const handleButtonClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
    window.open(link, '_blank')
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={classes.listingsHolder}
      >
        {widgetData.jobs.map((job, index) => (
          <Job
            key={index}
            job={job}
            classes={classes}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        ))}
      </Grid>
      <Grid
        item
        onClick={() =>
          handleButtonClick(
            widgetData.imageBanner.imageUrl,
            'handshake homepage',
          )
        }
      >
        <img
          src={image}
          alt={widgetData.imageBanner.imageBanner}
          className={classes.image}
        />
      </Grid>
    </Grid>
  )
}

function Job({ job, classes, widgetId, dispatch }) {
  const applyBtnTitle = 'Apply for Position'
  const handleButtonClick = (link, buttonTitle) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
    window.open(link, '_blank')
  }

  return (
    <Grid item className={classes.listingCard}>
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{job.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.description}>
          <Linkify properties={{ target: '_blank' }}>
            <Typography variant="body2" className={classes.description}>
              {job.description}
            </Typography>
          </Linkify>
        </AccordionDetails>
        <AccordionDetails style={{ justifyContent: 'center' }}>
          <Button
            onClick={() => handleButtonClick(job.link, applyBtnTitle)}
            className={classes.applyNowButton}
          >
            <Typography variant="button">{applyBtnTitle}</Typography>
          </Button>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default JobsInternships
