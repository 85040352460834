import { makeStyles } from '@material-ui/core/styles'

export const useRouteItemStyles = makeStyles((theme) => ({
  routeMarker: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    height: 117,
    width: 170,
    borderRadius: '6px',
    border: 'none',
    marginLeft: 8,
    marginRight: 8,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(550)]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down(370)]: {
      width: 90,
      height: 90,
    },
  },
  routeMarkerInactive: {
    opacity: 0.6,
  },

  routeNameLabel: {
    // Typography specific styles
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },
  routeNameContainer: {
    // Container specific styles
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%', // Adjust as necessary
    padding: '6px',
    borderRadius: '6px',
    marginBottom: '6px',
    overflow: 'hidden', // Ensure this is set to handle overflow with ellipsis
    backgroundColor: theme.palette.components.bottomSheetOverlay,
  },

  commonBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.components.bottomSheetOverlay,
  },

  firstRouteNameLabel: {
    textAlign: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down(580)]: {
      fontSize: '1.4rem',
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },

  nextRouteNameLabel: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },

  routeNameLabelNone: {
    textAlign: 'center',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    borderRadius: '2px',
    height: '100%',
    [theme.breakpoints.down(580)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '0.7rem',
    },
  },

  noMatchesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '8px',
  },

  noMatches: {
    fontSize: 20,
    fontWeight: '600',
    color: theme.palette.base.red,
  },
  contentValue: {
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: theme.palette.components.bottomSheetOverlay,
    borderRadius: '2px',
  },
}))
