/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react'
import { useDispatch } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box, ButtonBase } from '@material-ui/core'
import { newBrunswickDiningStyle } from './newBrunswickDiningStyles'
import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import { logDashboardEvent } from '../../../events'
import { getImageFromFirebaseWithHook } from '../../../../apps/helpers'

export default function NewBrunswickDining({
  widgetData,
  widgetId,
  showNotes,
}) {
  const dispatch = useDispatch()
  const classes = newBrunswickDiningStyle()
  const location = 'NB'
  const handleEventClick = (title, link) => {
    window.open(link, '_blank')

    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
  }

  return (
    <div className={showNotes === true ? classes.rootNotes : classes.root}>
      <Box className={classes.menuButtonsSection}>
        {widgetData[location].iconLinks?.map((iconLink) => (
          <ButtonBase
            key={iconLink.link}
            onClick={() => handleEventClick(iconLink.iconLabel, iconLink.link)}
            aria-label={iconLink.linkDescription}
            className={classes.menuButtonBase}
          >
            <IconPicker iconName="icon-spoon-knife" color="gray" size="2x" />
            <Typography variant="subtitle1">{iconLink.iconLabel}</Typography>
          </ButtonBase>
        ))}
      </Box>
      {widgetData[location].cardLinks?.length > 0 ? (
        widgetData[location].cardLinks?.map((data) => (
          <MyDiningCard
            key={data.link}
            classes={classes}
            handleEventClick={handleEventClick}
            data={data}
          />
        ))
      ) : (
        <Typography component="span" className={classes.heading}>
          No Dining Data To Display.
        </Typography>
      )}
      {widgetData[location].offCampusLinkMessage && (
        <Card className={classes.offlineContainer} variant="outlined">
          {widgetData[location].offCampusLinkMessage.text}
          <a
            href={widgetData[location].offCampusLinkMessage.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {` ${widgetData[location].offCampusLinkMessage.linkText}`}
          </a>
        </Card>
      )}
    </div>
  )
}

export const MyDiningCard = ({ classes, handleEventClick, data }) => {
  const [image, setImg] = React.useState('')
  React.useEffect(() => {
    if (data && data.imageLink) {
      getImageFromFirebaseWithHook(data.imageLink, setImg)
    }
  }, [])

  return (
    <Card
      className={classes.diningCard}
      onClick={() => handleEventClick(data.location_name, data.link)}
      variant="outlined"
      aria-label={data.location_name}
      key={data.id}
    >
      <CardMedia image={image} alt="Dining Halls" style={{ height: 120 }} />
      <CardContent
        style={{
          padding: 10,
          margin: 0,
        }}
      >
        <Typography variant="subtitle1" className={classes.heading}>
          {data.location_name}
        </Typography>
        <Typography variant="body" className={classes.heading}>
          {data.campus_location}
        </Typography>
      </CardContent>
    </Card>
  )
}
