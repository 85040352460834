import React from 'react'
import { Paper, Divider, IconButton, Typography, Grid } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  closeBottomSheet,
  fetchRoutes,
  fetchStops,
  handleStopClicked,
  resetMap,
  resetRouteShape,
  setLatLang,
  updateBottomSheet,
} from '../../sections/Map/redux/myMapsSlice'
import {
  handleViewBuildingInfoSheet,
  resetBuilding,
  resetParking,
} from '../../sections/Buildings/redux/myBuildingsSlice'
import { useBottomSheetStyles } from './BottomSheetStyles'
import BottomSheetStopInfo from './BottomSheetStopInfo/BottomSheetStopInfo'
import BottomSheetVehicleInfo from './BottomSheetVehicleInfo/BottomSheetVehicleInfo'
import BottomSheetRoutesList from './BottomSheetRoutesList/BottomSheetRoutesList'
import {
  ROUTE,
  ROUTES,
  VEHICLE,
  SEARCH,
  BUILDING,
  RESTAURANT,
  NEARESTSTOP,
  FIREBASEMARKER,
  PARKINGMAP,
  CAMDENENGAGEBUILDING,
} from '../../utils/constants'

import BottomSheetParking from './BottomSheetParking/BottomSheetParking'
import BottomSheetBuilding from './BottomSheetBuilding/BottomSheetBuilding'
import BottomSheetRestaurant from './BottomSheetRestaurant/BottomSheetRestaurant'
import BottomSheetNearestStop from './BottomSheetNearestStop/BottomSheetNearestStop'
import BottomSheetFirebaseMarker from './BottomSheetFirebaseMarker/BottomSheetFirebaseMarker'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import BottomSheetSearch from './BottomSheetSearch/BottomSheetSearch'
import { getLatLang } from '../../sections/Buildings/utils/helpers'
import BottomSheetCamdenEngageBuilding from './BottomSheetCamdenEngageBuilding/CamdenEngageBottomSheetBuilding'

function BottomSheet() {
  const buttonScaling = 1
  const classes = useBottomSheetStyles()
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  const { bottomSheet, selectedStop, selectedCampus } = useSelector(
    (s) => s.maps,
  )
  const isRouteOrVehicleComponent = [ROUTE, VEHICLE].includes(
    bottomSheet.component,
  )
  const { selectedBuildingID } = useSelector((s) => s.buildings)
  const [bottomSheetComponent, setBottomSheetComponent] = React.useState(null)
  const [sheetConfig, setSheetConfig] = React.useState({
    title: '',
    sheetColor: '',
    displayBackButton: false,
    displayCloseButton: false,
    headingColor: '',
    backButtonFunc: () => {},
  })
  const [useSheetHeader, setUseSheetHeader] = React.useState(false)

  React.useEffect(() => {
    selectWhichBottomSheet()
  }, [bottomSheet])

  React.useEffect(() => {
    checkShouldUseSheetHeader()
  }, [sheetConfig])

  const reset = () => {
    dispatch(resetMap())
    dispatch(resetBuilding())
    dispatch(resetParking())
  }
  const selectWhichBottomSheet = () => {
    const { component } = bottomSheet

    if (component != null) {
      if (component === ROUTES) {
        setBottomSheetComponent(
          <BottomSheetRoutesList setSheetConfig={setSheetConfig} />,
        )
      } else if (component === ROUTE) {
        setBottomSheetComponent(
          <BottomSheetStopInfo setSheetConfig={setSheetConfig} />,
        )
      } else if (component === VEHICLE) {
        setBottomSheetComponent(
          <BottomSheetVehicleInfo setSheetConfig={setSheetConfig} />,
        )
      } else if (component === SEARCH) {
        setBottomSheetComponent(
          <BottomSheetSearch setSheetConfig={setSheetConfig} />,
        )
      } else if (component === BUILDING) {
        setBottomSheetComponent(
          <BottomSheetBuilding setSheetConfig={setSheetConfig} />,
        )
      } else if (component === RESTAURANT) {
        setBottomSheetComponent(
          <BottomSheetRestaurant setSheetConfig={setSheetConfig} />,
        )
      } else if (component === NEARESTSTOP) {
        setBottomSheetComponent(
          <BottomSheetNearestStop setSheetConfig={setSheetConfig} />,
        )
      } else if (component === FIREBASEMARKER) {
        setBottomSheetComponent(
          <BottomSheetFirebaseMarker setSheetConfig={setSheetConfig} />,
        )
      } else if (component === PARKINGMAP) {
        setBottomSheetComponent(
          <BottomSheetParking setSheetConfig={setSheetConfig} />,
        )
      } else if (component === CAMDENENGAGEBUILDING) {
        setBottomSheetComponent(
          <BottomSheetCamdenEngageBuilding setSheetConfig={setSheetConfig} />,
        )
      }
    }
  }

  // Back Button
  const backButton = () => {
    const { component } = bottomSheet
    if (component === ROUTE) {
      reset()
      dispatch(fetchRoutes())
      dispatch(fetchStops())
      dispatch(handleStopClicked(selectedStop))
      dispatch(resetRouteShape())
    } else if (component === RESTAURANT || component === NEARESTSTOP) {
      dispatch(handleViewBuildingInfoSheet(selectedBuildingID))
      dispatch(updateBottomSheet(BUILDING))
    } else if (component === PARKINGMAP || component === BUILDING) {
      sheetConfig.backButtonFunc.myFunction()
    }
  }

  const checkShouldUseSheetHeader = () => {
    const { title, displayBackButton, displayCloseButton } = sheetConfig
    setUseSheetHeader(
      !(title === '' && !displayBackButton && !displayCloseButton),
    )
  }

  const closeSheet = () => {
    dispatch(setLatLang(getLatLang(selectedCampus)))
    dispatch(resetRouteShape())
    dispatch(closeBottomSheet(null))
    dispatch(fetchStops())
    reset()
  }

  // In mobile, we want to set custom heights depending on the component
  const getClassName = () => {
    // Define a map of component names to class names
    const mobileClassMap = {
      [ROUTES]: classes.routeItemsRoot,
      [PARKINGMAP]: classes.routeItemsRoot,
      [SEARCH]: classes.searchRoot,
      // Add other components and their corresponding classes as needed
    }

    // Check if the current component has a special class when isMobile is true
    if (isMobile && mobileClassMap[bottomSheet.component]) {
      return mobileClassMap[bottomSheet.component]
    }

    // Return the default class if no special class is found or if isMobile is false
    return classes.specialRoot
  }

  return (
    <div>
      {bottomSheet.open && (
        <Paper
          className={getClassName()}
          style={{ background: sheetConfig.sheetColor }}
        >
          <Grid container direction="row" justifyContent="space-between">
            {useSheetHeader && (
              <>
                <Grid item>
                  {sheetConfig.displayBackButton ? (
                    <IconButton
                      edge="start"
                      className={classes.backButton}
                      aria-label="back button"
                      onClick={() => backButton()}
                    >
                      <ArrowBackIcon
                        className={classes.backIcon}
                        style={{
                          color: sheetConfig.sheetColor
                            ? sheetConfig.sheetColor
                            : '#424242',
                          fontSize: 29 * buttonScaling,
                          backgroundColor: sheetConfig.headingColor,
                        }}
                      />
                    </IconButton>
                  ) : null}
                </Grid>
                <Grid item style={{ marginTop: 6 }}>
                  <Typography
                    variant="h3"
                    className={
                      isRouteOrVehicleComponent
                        ? classes.solidTitle
                        : classes.title
                    }
                  >
                    {sheetConfig.title}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item>
                  {sheetConfig.displayCloseButton ? (
                    <IconButton
                      edge="start"
                      className={classes.closeButton}
                      style={{ color: sheetConfig.headingColor }}
                      aria-label="close bottom sheet"
                      onClick={() => closeSheet()}
                    >
                      <CancelIcon style={{ fontSize: 35 * buttonScaling }} />
                    </IconButton>
                  ) : null}
                </Grid>
              </>
            )}

            {bottomSheetComponent}
          </Grid>
        </Paper>
      )}
    </div>
  )
}

export default BottomSheet
