/* eslint-disable no-lonely-if */
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography as Text, Button } from '@material-ui/core'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import App from '../App/App'
import CustomDotGroup from './AppCarouselDots'
import AppCarouselStyles from './AppCarouselStyles'
import ShowAppList from '../ShowAppList/ShowAppList'
import { generateRandString } from '../../helpers'

/**
 * App Carousel For Web
 * @param isMobile
 * @param category
 * @param appList
 * @param dimensions
 * @param slideNumber
 * @param isGuest
 * @param showCategoryText
 * @param carouselHeader
 */
const AppCarouselCombined = React.memo(function AppCarouselCombined({
  appList,
  carouselHeader = null,
  carouselType = 'carousel',
  category,
  dimensions,
  id = null,
  isGuest = false,
  isMobile,
  showCategoryText = true, // showCategoryText = false when we are using it within another page like info or category
}) {
  const history = useHistory()
  const classes = AppCarouselStyles()
  // Used for web
  const [seeMore, setSeeMore] = React.useState(false)
  const [numSlides, setNumSlides] = React.useState(0)

  // Used for mobile when we want to increase num rows to decrease num carousel dots
  // eslint-disable-next-line no-unused-vars
  const [adjustedCarouselHeight, setAdjustedCarouselHeight] = React.useState(0)
  // Contains 2D array of apps broken down into page
  const [pages, setPages] = React.useState([])
  const carouselWidth = window.innerWidth
  const { size, numberOfAppsPerSlide, carouselHeight, gridWidth } = dimensions
  const [height, setHeight] = React.useState(carouselHeight)

  const isClickable = carouselType !== 'forYou'

  const slideNumber = Math.ceil(
    appList.length / dimensions.numberOfAppsPerSlide,
  )

  // If dimensions change, recreate the app pages
  // If appList updates, recreate the apps pages
  React.useEffect(() => {
    checkSizeAndCreatePages()
    determineCarouselHeight()
  }, [isMobile, numberOfAppsPerSlide, appList])

  // the Height of the Carousel is determined by the num of Apps
  const determineCarouselHeight = () => {
    const numberOfApps = appList.length
    if (size.includes('mobile')) {
      if (numberOfApps === 1) {
        setHeight(carouselHeight - 200)
      } else if (numberOfApps === 2) {
        setHeight(carouselHeight - 140)
      }
    } else {
      if (numberOfApps <= 4) {
        setHeight(carouselHeight - 170)
      }
    }
  }

  const createPages = (apps, numAppsPerSlide) => {
    const pageList = []
    let startIndex = 0
    let endIndex = numAppsPerSlide
    for (let i = 0; i < slideNumber; i++) {
      const slicedList = {
        id: i.toString(),
        list: apps.slice(startIndex, endIndex),
      }
      pageList.push(slicedList)
      startIndex = endIndex
      endIndex += numAppsPerSlide
    }
    setPages(pageList)
  }

  // Adjust number of apps to add per slide page
  // For every app you add, adjust the height.
  // Finally set the height to use for css
  const createAdjustedCarouselHeightAndAdjustNumberOfAppsPerSlideForMobile = (
    numApps,
    carouselHeightToAdjust,
    numAppsPerSlide,
  ) => {
    let newHeight = carouselHeightToAdjust
    let newNumApps = numAppsPerSlide
    if (numApps > 55) {
      newNumApps += 1
      newHeight += 80
    } else if (numApps > 100) {
      newNumApps += 2
      newHeight += 130
    }
    setAdjustedCarouselHeight(newHeight)
    return newNumApps
  }

  const checkSizeAndCreatePages = () => {
    let numberOfSlides = null
    let numberOfAppsPerSlidePerPage = null

    if (size.includes('mobile')) {
      const numAppsPerSlide =
        createAdjustedCarouselHeightAndAdjustNumberOfAppsPerSlideForMobile(
          appList.length,
          carouselHeight,
          numberOfAppsPerSlide,
        )
      numberOfSlides = Math.ceil(appList.length / numAppsPerSlide)
      numberOfAppsPerSlidePerPage = numAppsPerSlide
    } else {
      numberOfSlides = Math.ceil(
        appList.length / dimensions.numberOfAppsPerSlide,
      )
      numberOfAppsPerSlidePerPage = dimensions.numberOfAppsPerSlide
    }
    createPages(appList, numberOfAppsPerSlidePerPage)
    setNumSlides(numberOfSlides)
  }

  const AppCarouselMenu = () => (
    <div className={classes.carouselContainer}>
      {
        // CarouselHeader
        showCategoryText &&
          (carouselHeader === null ? (
            <div
              onClick={isClickable ? handleCategoryClick : null}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleCategoryClick()
              }}
              role="button"
              tabIndex="0" // Makes the div focusable
              style={{ cursor: isClickable ? 'pointer' : 'auto' }}
            >
              <Text className={classes.categoryText}>{category}</Text>
            </div>
          ) : (
            <div>
              <Text className={classes.categoryText}>{carouselHeader}</Text>
            </div>
          ))
      }
      {pages.length > 1 ? (
        <Button onClick={toggleSeeMore}>
          {seeMore ? (
            <Text className={classes.seeAllButtonText}>See Less</Text>
          ) : (
            <Text className={classes.seeAllButtonText}>See All</Text>
          )}
        </Button>
      ) : null}
    </div>
  )

  const AppCarouselBody = () => (seeMore ? seeAllApps() : createAppCarousel())

  const toggleSeeMore = () =>
    isMobile
      ? history.push({
          pathname: '/SeeAllAppsPageMobile',
          search: `?id=${id}&type=${carouselType}`,
        })
      : setSeeMore(!seeMore)

  const handleCategoryClick = () =>
    !isMobile &&
    history.push({
      pathname: './infoPage',
      search: `?type=Slider&id=${id}`,
    })

  const createAppCarousel = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CarouselProvider
        naturalSlideWidth={carouselWidth}
        naturalSlideHeight={height}
        totalSlides={isMobile ? slideNumber : numSlides}
        // style={{
        //   width: isMobile ? null : carouselWidth,
        //   height: isMobile ? null : carouselHeight,
        // }}
      >
        <Slider key={category + generateRandString()}>
          {pages.map((page, index) => (
            <Slide
              key={page.id + generateRandString()}
              index={index}
              style={{
                height,
              }}
            >
              <Grid container>
                {page.list.map((app) => (
                  <Grid item key={app.id} xs={gridWidth}>
                    <App
                      isGuest={isGuest}
                      key={app.id}
                      app={app}
                      isMobile={isMobile}
                    />
                  </Grid>
                ))}
              </Grid>
            </Slide>
          ))}
        </Slider>
        {numSlides > 1 && <AppCarouselDots />}
      </CarouselProvider>
    </div>
  )

  const AppCarouselDots = () => (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {!isMobile && (
        <Grid item>
          <ButtonBack className={classes.buttonStyle} color="secondary">
            <ArrowBackIosIcon className={classes.backArrow} />
          </ButtonBack>
        </Grid>
      )}

      <Grid item className={classes.dotGroup}>
        <div className={classes.customDotGroupContainer}>
          <CustomDotGroup slides={numSlides} size="mini" />
        </div>
      </Grid>

      {!isMobile && (
        <Grid item>
          <ButtonNext className={classes.buttonStyle}>
            <ArrowForwardIosIcon className={classes.forwardArrow} />
          </ButtonNext>
        </Grid>
      )}
    </Grid>
  )

  const seeAllApps = () => (
    <ShowAppList
      isMobile={isMobile}
      inputAppList={appList}
      isGuest={isGuest}
      appDimensions={dimensions}
      justify="center"
    />
  )

  return (
    <div className={classes.carouselContainerDiv}>
      <AppCarouselMenu />
      <AppCarouselBody />
    </div>
  )
})

export default AppCarouselCombined
