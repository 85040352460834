import { makeStyles } from '@material-ui/core/styles'

export const itServiceCenterStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  mainGrid: {
    height: '475px',
    display: 'flex',
  },
  scrollContent: {
    flex: 1,
    minHeight: '1px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  input: {
    color: theme.palette.base.white,
  },
  iconName: {
    color: theme.palette.base.white,
  },
  searchBar: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  imgContainer: {
    marginTop: 3,
    alignSelf: 'center',
  },
  img: {
    height: 35,
    width: 'auto',
  },
  modalTabs: {
    width: 335,
    height: 35,
    [theme.breakpoints.down(370)]: {
      width: 300,
    },
  },
  announcementList: {
    width: '100%',
    overflow: 'auto',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  casesList: {
    width: '100%',
    overflow: 'auto',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  bottomContainer: {
    width: '100%',
    // paddingTop: '5px',
    // backgroundColor: theme.palette.base.blueGray,
  },
  grid1: {
    // borderColor: `${theme.palette.backgroundColor.ternary} !important`,
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    // paddingTop: '5px',
    backgroundColor: theme.palette.base.blueGray,
  },
  icon: {
    color: theme.palette.base.white,
    width: '100%',
    fontSize: 25,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  tabLink: {
    position: 'relative',
    fontSize: '10px',
    color: 'white',
    bottom: 4,
  },
  circleIcon: {
    fontSize: 70,
    color: theme.palette.base.green,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 4px 0px 4px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: 'rgba(255, 255, 255, 0.21)',
      borderRadius: '4px',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.21)',
      borderRadius: '4px',
    },
  },
  tinyText: {
    fontSize: '10px',
    marginTop: '6.5px',
  },
}))

export const AnnouncementObjectStyles = makeStyles(() => ({
  gridItem: {
    width: '100%',
  },
  announcementCardBackground: {
    height: 'auto',
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    width: '100%',
  },
}))

export const MyCasesObjectStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
  },
  caseCardBackground: {
    height: 'auto',
    padding: 15,
    paddingBottom: 10,
    marginBottom: 15,
    width: '100%',
  },
  caseIDTextOpen: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.base.green,
  },
  caseIDTextClosed: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.base.red,
  },
  iconButton: {
    position: 'relative',
    right: 5,
    padding: 0,
    paddingBottom: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  expandOpen: {
    textAlign: 'left',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandClosed: {
    justifyContent: 'left',
    textAlign: 'left',
    transform: 'rotate(180deg)',
  },
  caseDescription: {
    color: theme.palette.primary.headerText,
    width: '270px !important',
    textAlign: 'left',
  },
  descriptionText: {
    width: '270px !important',
    wordWrap: 'break-word',
    overflowWrap: 'break-word !important',
    display: 'inline-block',
  },
}))

export const ToDoObjectStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
    marginTop: '-5px',
  },
  caseCardBackground: {
    height: 'auto',
    padding: 15,
    paddingBottom: 10,
    marginBottom: 15,
    width: '100%',
  },
  linkStyle: {
    marginBottom: '-5px',
  },
  greenText: {
    color: theme.palette.base.green,
  },
  greenIcon: {
    marginLeft: 13,
    color: theme.palette.base.green,
  },
  yellowText: {
    color: theme.palette.base.orange,
  },
  yellowIcon: {
    marginLeft: 13,
    color: theme.palette.base.orange,
  },
}))
