import { useState, useEffect } from 'react'

/**
 * Sets the dimensions of the carousels as well as how many slides are in the carousel per page
 * Responsive based on window size
 */

// Could not use material ui's theme.breakpoints because I couldn't nest that hook into this hook.
// It caused an issue so the only work around that I could think of was this method

// Define the dimensions to be passed to the app carousel here
const dimensions = {
  mobile: {
    size: 'mobile',
    carouselHeight: 310, // Height of each carousel slider
    numberOfAppsPerSlide: 4, // How many apps to be rendered per carousel page
    gridWidth: 12, // Width of each app per row on carousel slider
  },
  tablet: {
    size: 'tablet',
    carouselHeight: 270,
    numberOfAppsPerSlide: 6,
    gridWidth: 6,
  },
  desktop: {
    size: 'desktop',
    carouselHeight: 270,
    numberOfAppsPerSlide: 9,
    gridWidth: 4,
  },
  desktopLG: {
    size: 'desktopLG',
    carouselHeight: 270,
    numberOfAppsPerSlide: 12,
    gridWidth: 3,
  },
}

// Define your break points here
function getCurrentBreakpoint() {
  const screenWidth = window.innerWidth
  // Break point values in px
  const s = 640
  const md = 768
  const xxl = 1350

  // Find which breakpoint
  const mobile = screenWidth <= s
  const tablet = screenWidth > s && screenWidth <= md
  const desktopLG = screenWidth > xxl

  let breakPoint = null

  // Set the breakpoint type to return
  if (mobile) {
    // Up to iPhonex
    breakPoint = 'mobile'
  } else if (desktopLG) {
    breakPoint = 'desktopLG'
  } else if (tablet) {
    breakPoint = 'tablet'
  } else {
    breakPoint = 'desktop'
  }
  return breakPoint
}

const SetCarouselDimensions = () => {
  const getCarouselDimensionsInitialState = () => {
    // Sets the carousel size state initially
    const screenSize = getCurrentBreakpoint()
    return dimensions[screenSize]
  }

  const [carouselDimensions, setCarouselDimensions] = useState(
    getCarouselDimensionsInitialState,
  )

  useEffect(() => {
    // Sets carousel dimensions responsively
    const getCarouselDimensions = () => {
      const screenWidth = getCurrentBreakpoint()
      const dim = dimensions[screenWidth]
      setCarouselDimensions(dim)
    }

    getCarouselDimensions()
    window.addEventListener('resize', getCarouselDimensions)

    return () => window.removeEventListener('resize', getCarouselDimensions)
  }, [])

  return carouselDimensions
}

export default SetCarouselDimensions
