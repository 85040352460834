import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  footer: {
    margin: '0',
    width: '100%',
    height: '45px',
    borderTop: '1px solid',
    borderColor: theme.palette.backgroundColor.ternary,
    backgroundColor: theme.palette.backgroundColor.secondary,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  footerGrid: {
    height: '100%',
  },
}))

export default styles
