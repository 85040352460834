import React, { useState, useRef } from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'

import { focusAlert, selectMyAlertsRef } from '../MyAlertsSlice'
import AlertList from './AlertList'
import {
  RutgersTab,
  RutgersTabs,
} from '../../../DashboardComponents/Tabs/RutgersTabs'
import { TabPanel } from '../../../DashboardComponents/TabPanel/TabPanel'

function AlertGUI({ data, classes, dialogHandler }) {
  const [value, setValue] = useState(0)
  const { personalAlerts, emergencyAlerts, itAlerts } = data.alerts
  const [activeLabel, historyLabel] = data.categories
  const personalLabel = <Typography variant="body1">{activeLabel}</Typography>
  const emergencyLabel = <Typography variant="body1">{historyLabel}</Typography>
  const dispatch = useDispatch()
  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const [activeList, setActiveList] = React.useState([])
  const [historyList, setHistoryList] = React.useState([])

  React.useEffect(() => {
    // Removed ...refundAlerts for now 3/29/24
    const combinedList = [...personalAlerts, ...itAlerts]
    let newerActiveList = combinedList.filter(
      (alert) => alert.acknowledgedTS === '',
    )
    newerActiveList = [...emergencyAlerts, ...newerActiveList]
    setActiveList(newerActiveList?.filter((item) => item))
    const newHistoryList = combinedList?.filter(
      (alert) => alert.acknowledgedTS !== '',
    )
    setHistoryList(newHistoryList?.filter((item) => item))
  }, [data.alerts])

  const alertsRef = useRef(null)
  const focusAlertsNotifier = useSelector(selectMyAlertsRef)
  React.useEffect(() => {
    if (focusAlertsNotifier) {
      alertsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
      dispatch(focusAlert())
    }
  }, [focusAlertsNotifier])

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.retainer}
      ref={alertsRef}
    >
      <Grid item className={classes.modalTab}>
        <RutgersTabs value={value} onChange={handleChange}>
          <RutgersTab label={personalLabel} />
          <RutgersTab label={emergencyLabel} />
        </RutgersTabs>
      </Grid>
      <div
        className={
          value === 0
            ? classes.alertsListContainer
            : classes.alertsListContainerHistory
        }
      >
        <TabPanel value={value} index={0}>
          <AlertList
            alertList={activeList}
            classes={classes}
            dialogHandler={dialogHandler}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AlertList
            alertList={historyList}
            classes={classes}
            dialogHandler={dialogHandler}
            alertHistory
            dispatch={dispatch}
          />
        </TabPanel>
      </div>
    </Grid>
  )
}

export default AlertGUI
