import { makeStyles } from '@material-ui/core/styles'

export const bottomSheetStopInfoStyles = makeStyles((theme) => ({
  header: {
    fontSize: 26,
    marginBottom: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(580)]: {
      fontSize: 24,
    },
  },
  directionBusIcon: {
    fontSize: 30,
    marginRight: 4,
  },
  firstStopNameLabel: {
    textAlign: 'center',
    paddingBottom: 8,
    fontSize: 24,
    [theme.breakpoints.down(580)]: {
      fontSize: 22,
    },
  },
  stopNameLabel: {
    textAlign: 'center',
    width: '100%',
    fontSize: 17,
    [theme.breakpoints.down(580)]: {
      fontSize: 16,
    },
  },

  centerContent: {
    width: '100%',
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: theme.palette.components.bottomSheetOverlay,
    borderRadius: '2px',
    padding: '2px 6px',
    margin: '0px 6px 8px 6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 155,
  },
}))
